<template>
  <ul class="goods-comment">
      <li  v-for="(item, index) in data" :key="index" class="wrapper">
        <div class="comment-person" v-if="item.user_info">
          <img :src="item.user_info.pic_url" alt="" v-if="item.user_info.pic_url">
          <img src="@/assets/suggest/icon_huodong@2x.png" alt="" v-else>
          <span>{{item.user_info.nickname}}</span>
        </div>
        <div class="rate-date">
          <div>
            <img v-for="i in item.s_comment" :key="i" src="@/assets/goods/star.png" alt="star">
          </div>
          <span>{{item.created_at}}</span>
        </div>
        <div class="content">
          {{item.content? item.content: '此用户没有填写评价。'}}
        </div>
        <div class="media" v-if="item.video_url || item.pic_list.length > 0">
          <img v-if="item.video_url" :src="item.video_url.video_pic" alt="" @click="playVideo(item.video_url)">
          <img v-if="item.video_url" src="@/assets/goods/play.png" alt="" class="play-video" @click="playVideo(item.video_url)">
          <img v-for="(el, ind) in item.pic_list" :key="ind" :src="el" alt="" @click="viewImg(item.pic_list, ind)">
        </div>
        <div class="store-reply" v-if="item.kf_reply">
          商家回复： {{item.kf_reply.content}}
        </div>
      </li>
      <van-popup v-model="showVideo" position="left" :style="{width:'100%',height: '100%', background: '#000'}" closeable close-icon="close" @closed="onPlayerPause(player)">
        <video-player
          class="video-player vjs-custom-skin video"
          ref="videoPlayer"
          autoplay
          playsinline
          x5-playsinline
          webkit-playsinline
          :mtt-playsinline="true"
          :x5-video-player-type="h5" 
          x5-video-player-fullscreen
          :options="playerOptions"
          @pause="onPlayerPause($event)"
        ></video-player>
      </van-popup>
  </ul>
</template>

<script>
import { ImagePreview } from 'vant'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css';
/* eslint-disable */
export default {
  name:'GoodsColumns',
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      h5:'h5-page',
      playerOptions : {
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        aspectRatio: '375:375', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        poster: '', //你的封面地址
        sources: [{
          type: "video/mp4",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
          src: '' //url地址
        }],
        width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: '视频加载失败', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: false, // 是否显示全屏按钮
          volumePanel: false, // 是否显示声音按钮
        },
      },
      showVideo: false,
      player: ''
    }
  },
  methods:{
    handleCart(goods,e){
      console.log(goods)
      this.$emit('cartClick',goods,e)
    },
    // 暂停播放
    onPlayerPause(player) {
      player.pause()
    },
    viewImg(list, index) {
      let imgList = []
      list.forEach(el => {
        imgList.push(this.$store.state.imgPreUrl + el)
      })
      // 预览图片
      ImagePreview({
        images: imgList,
        startPosition: index - 0
      })
    },
    playVideo(src) {
      // this.playerOptions.poster = this.$store.state.ossUrl + src.video_pic
      this.playerOptions.sources[0].src = this.$store.state.ossUrl + src.url
      this.showVideo = true
      this.$nextTick(() => {
        this.$refs.videoPlayer.player.play()
        this.player = this.$refs.videoPlayer.player
      })
    }
  }
}
</script>

<style lang="less" scoped>
.goods-comment {
 width: 100%;
//  padding: 0 0 20px;
 .wrapper {
   margin-bottom: 10px;
   background: #fff;
   padding: 0 16px 20px;
 }
 .comment-person {
   width: 100;
   display: flex;
   align-items: center;
   padding: 10px 0;
   >img {
     width: 34px;
     height: 34px;
     border-radius: 50%;
     margin-right: 8px;
   }
   >span {
     font-size:14px;
      font-weight:500;
      color:rgba(51,51,51,1);
      line-height:20px;
   }
 }
 .rate-date {
   display: flex;
   align-items: center;
   justify-content: space-between;
   >div>img {
     width: 18px;
     height: 18px;
     margin-right: 2px;
   }
   >span {
      font-size:14px;
      font-weight:500;
      color: #666666;
      line-height:20px;
   }
 }
 .content {
    font-size:14px;
    font-weight:500;
    color: #333;
    line-height:20px;
    padding: 10px 0 12px;
 }
 .media {
   width: 100%;
   min-height: 109px;
   display: grid;
   grid-template-columns:repeat(auto-fill, 109px);;
   grid-template-rows: 109px;
   grid-column-gap: 8px ;
   grid-row-gap: 12px;
   position: relative;
   >img {
     width: 109px;
     height: 109px;
     border-radius: 3px;
   }
 }
 .play-video{position: absolute;top:35px;left:35px;width:40px !important;height:40px !important;opacity: 0.8}
 /deep/.video-player{position:absolute;left:0;top:50%;transform: translateY(-50%);width:100%;
  .video-js .vjs-tech{height:auto !important;position: relative !important;}
  .vjs-fluid{padding:0 !important}
  .video-js.vjs-fluid, .video-js.vjs-16-9, .video-js.vjs-4-3{height:fit-content}
 }
 .store-reply{margin-top:10px;font-size: 14px;background-color: #f7f7f7;padding:7px 10px;border-radius: 6px;color:#444;line-height: 22px;}
}
</style>