<template>
  <section class="m-goods m-scroll"  @scroll="handleScroll($event)"  ref="wrapper">

    <!-- 视频 & 轮播图 -->
    <goods-swipe ref="banner" :images="goodsInfo.round_pic_url" :videoData="goodsInfo.video_url == '' ? {} : goodsInfo.video_url"
    :hasVideo="goodsInfo.video_url != null && goodsInfo.video_url != ''" :goodsInfo="goodsInfo"></goods-swipe>

    <!-- 头部导航 -->
    <div class="head-bar m-padding" ref="bar">
      <img @click="goodsBack" src="@/assets/goods/icon_back_2@2x.png" alt="img">
      <div>
        <router-link to="/zh/cart" >
          <img src="@/assets/goods/cart.png" alt="img" :class="{'cart':cartAnimate}">
          <m-dot :animate="dotAnimate" ref="dot"></m-dot>
        </router-link>
        <router-link to="/">
          <img src="@/assets/goods/icon_home_3@2x.png" alt="">
        </router-link>
      </div>
    </div>

    <!-- 价格 分享 -->
    <div class="price-share m-padding">
      <div class="price" v-if="goodsInfo">
        <div v-if="$route.query.pre && $route.query.pre == 1">
          <p>
            <span>$</span>
            <span>{{(goodsInfo.pre_info.pre_min_price * (goodsInfo.off == 0 ? 1 : goodsInfo.off / 100)).toFixed(2)}}</span>
            <span v-if="goodsInfo.pre_info.pre_min_price != goodsInfo.pre_info.pre_max_price"> ~ </span>
            <span v-if="goodsInfo.pre_info.pre_min_price != goodsInfo.pre_info.pre_max_price">{{(goodsInfo.pre_info.pre_max_price * (goodsInfo.off == 0 ? 1 : goodsInfo.off / 100)).toFixed(2)}}</span>
          </p>
          <p v-if="goodsInfo.off != 0">
            <span v-if="goodsInfo.off != 0">${{goodsInfo.pre_info.pre_min_price}}</span>
            <span v-if="goodsInfo.pre_info.pre_min_price != goodsInfo.pre_info.pre_max_price"> ~ </span>
            <span v-if="goodsInfo.pre_info.pre_min_price != goodsInfo.pre_info.pre_max_price">{{goodsInfo.pre_info.pre_max_price}}</span>
          </p>
        </div>
        <div v-else>
          <p>
            <span>$</span>
            <span>{{(goodsInfo.activity_min_price *1).toFixed(0) != 0 ? goodsInfo.activity_min_price : (goodsInfo.min_price * (goodsInfo.off == 0 ? 1 : goodsInfo.off / 100)).toFixed(2)}}</span>
            <span v-if="goodsInfo.min_price != goodsInfo.max_price || (goodsInfo.activity_min_price != goodsInfo.activity_max_price)"> ~ </span>
            <span v-if="goodsInfo.min_price != goodsInfo.max_price || (goodsInfo.activity_min_price != goodsInfo.activity_max_price)">{{(goodsInfo.activity_min_price *1).toFixed(0) != 0 ? goodsInfo.activity_max_price : (goodsInfo.max_price * (goodsInfo.off == 0 ? 1 : goodsInfo.off / 100)).toFixed(2)}}</span>
          </p>
          <p v-if="(goodsInfo.activity_min_price *1).toFixed(0) != 0 ? true : goodsInfo.off != 0">
            <span v-if="goodsInfo.min_price != goodsInfo.activity_min_price">
              <span>${{goodsInfo.min_price}}</span>
              <span v-if="goodsInfo.min_price != goodsInfo.max_price"> ~ </span>
              <span v-if="goodsInfo.min_price != goodsInfo.max_price">{{goodsInfo.max_price}}</span>
            </span>
          </p>
        </div>
        <div class="agent" v-if="goodsInfo.agent">合伙人代理价： 
          <span v-if="goodsInfo.agent_price_range">${{goodsInfo.agent_price_range.min}}
            <span v-if="goodsInfo.agent_price_range.max != goodsInfo.agent_price_range.min">~{{goodsInfo.agent_price_range.max}}</span>
          </span>
        </div>
      </div>
      <div class="share" @click="shareHandle" v-if="$route.params.id != '3751'">
        <img src="@/assets/goods/icon_fenxiang@2x.png" alt="img">
        <span>分享</span>
      </div>
    </div>

    <!-- 签到价 -->
    <div class="sign-up" v-if="$route.query.sign && $route.query.sign == 1">
      <div class="sign-up-box">
        签到价 ${{goodsInfo.sign_price}}
      </div>
    </div>

    <!-- 开通会员 -->
    <div class="vip" v-if="false">
      <div>
        <p>开通VIP会员，即享会员价</p>
        <p>立即开通</p>
      </div>
    </div>

    <!-- 商品名称 -->
    <p class="title m-padding van-multi-ellipsis--l2">
      <img src="@/assets/goods/pre_new.png" alt="" v-if="isNew">
      {{goodsInfo.goods_info.name}}
    </p>

    <!-- 所属店铺 商品游览量 -->
    <div class="merchant van-hairline--bottom">
      <div @click="gotoLink">
        <img src="@/assets/index/icon_dianpu@3x.png" alt="store">
        <span>{{goodsInfo.shops_info ? goodsInfo.shops_info.title : 'Shop'}}</span>
      </div>
      <div>浏览量{{goodsInfo.goods_info.visits*7?goodsInfo.goods_info.visits*7 : 0}}</div>
    </div>

    <!-- 商品服务；选择SKU -->
    <div class="sku-select m-padding">
      <div class="servies-cell" v-if="isNew">
        <p>配送</p>
        <p>该商品预计{{batch.purchase_period + batch.order_period + batch.delivery_date}}个工作日到仓</p>
      </div>
      <div class="servies-cell">
        <p>服务</p>
        <p v-if="goodsInfo.after_sale == 1">该商品支持七天无理由退货</p>
        <p v-else-if="goodsInfo.after_sale == 2">非质量问题不退不换</p>
        <p v-else>无售后</p>
      </div>
      <div class="servies-cell" v-if="goodsInfo.after_sale_content">
        <p>说明</p>
        <p>{{goodsInfo.after_sale_content}}</p>
      </div>
      <div class="sku-cell" @click="handleShowSku">
        <p>请选择</p>
        <p>{{selectSkuList.join(' | ') || '暂无选择'}}</p>
        <img src="@/assets/order/icon-fanhui@2x.png" alt="img">
      </div>
    </div>

    <!-- 商品评论 -->
    <div class="comment-title m-padding van-hairline--bottom">
      <span>商品评论</span>
      <!-- <span>暂无评论</span> -->
      <div>
        <router-link :to="`/zh/goods/comment/${$route.params.id}`">更多评论</router-link>
        <img  src="@/assets/order/icon-fanhui@2x.png" alt="img">
      </div>
      
    </div>
    <!-- <div class="comment-content">
      <div class="comment-empty">暂无评论</div>
    </div> -->
    <div class="comment-content">
      <comment-list :data="commentList"></comment-list>
    </div>


    <!-- 为您推荐 热销产品 -->
    <div class="tabs">
      <div class="tab-item" :class="{'tab-item-active':tab == 0}" @click="handleTab(0)">为您推荐</div>
      <div class="tab-item" :class="{'tab-item-active':tab == 1}" @click="handleTab(1)">热销产品</div>
      <div class="tab-active" ref="tabActive"></div>
    </div>

    <van-swipe :autoplay="0" :loop="false" class="tab-swipe" :stop-propagation="false">
      <van-swipe-item v-for="(image, index) in goodsList[tab].length" :key="index" >
        <goods-columns @cartClick="handleCart" :data="goodsList[tab][index]" :columns="3"></goods-columns>
      </van-swipe-item>
    </van-swipe>

    <!-- 回到顶部 -->
    <!-- <transition name="van-fade">
      <div v-show="scrollTop" @click="onScrollTop"  class="scroll-top"></div>
    </transition> -->
    <m-scroll-top :show="scrollTop" :dom="$refs.wrapper"></m-scroll-top>
    

    <!-- 底部控制栏 -->
    <div class="foot-bar">
      <router-link to="/zh/presale?id=6" v-if="goodsInfo.merchant_id == 1 && goodsInfo.pre_info.is_pre == 1">
        <img src="@/assets/goods/pre.png" alt="store">
        <span>预售</span>
      </router-link>
      <router-link to="/zh/mooby?id=4" v-if="goodsInfo.merchant_id == 1 && goodsInfo.pre_info.is_pre == 0">
        <img src="@/assets/goods/icon_dianpu@2x.png" alt="servies">
        <span>自营</span>
      </router-link>
      <router-link :to="`/zh/merchant/index/${goodsInfo.merchant_id}`" v-if="goodsInfo.merchant_id != 1">
        <img src="@/assets/goods/icon_dianpu@2x.png" alt="servies">
        <span>店铺</span>
      </router-link>
      <router-link to="/zh/customer">
        <img src="@/assets/goods/icon_kefu@2x.png" alt="servies">
        <span>客服</span>
      </router-link>
      <span @click="favoriteGoodsHandle">
        <img src="@/assets/goods/icon_shouchang@2x.png" alt="img" v-if="goodsInfo.goods_collect == null">
        <img src="@/assets/goods/icon_shouchang@2x_a.png" alt="img" v-else>
        <span>收藏</span>
      </span>
      <div class="btns" v-if="$route.params.id != '3751'">
        <span @click="showSkuModel('加入购物车')" v-if="!isQOrder">加入购物车</span>
        <span @click="showSkuModel('立即购买')" :style="{'border-radius': isQOrder ? '5px' : '0px 5px 5px 0px'}">立即购买</span>
      </div>
      <div class="btns" v-else>
        <span @click="handleBargain" :style="{'border-radius': isQOrder ? '5px' : '5px'}">获取折扣</span>
      </div>
    </div>

    <!-- 商品详情图 -->
    <m-divider title="商品详情" class="detail-title"></m-divider>
    <div class="detail-img">
      <img :src="item.url" v-for="(item, index) in goodsInfoImg" :key="index" alt="">
    </div>


    <!-- 选择SKU 加入购物车 -->
    <m-sku :title="skuBtn" :show="skuModal" @select="selectSku"
    @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId" :off="off"></m-sku>

    <!-- 快速下单 -->
    <transition name="van-fade">
      <Qlogin @success="loginSuccess" @close="isViewLogin = false" v-if="isViewLogin" />
    </transition>
  </section>
</template>

<script>
import GoodsSwipe   from '@/components/zh/goods-swipe.vue'
import CommentList  from '@/components/zh/goods-comment.vue'
import MDot         from '@/components/zh/m-dot.vue'
import MSku         from '@/components/zh/m-sku.vue'
import GoodsColumns from '@/components/zh/goods-columns.vue'
import MDivider     from '@/components/zh/m-divider.vue'
import MScrollTop   from '@/components/zh/m-scroll-top.vue'
import Qlogin       from './qlogin.vue'

import { parseTime,_isIOS } from '@/untils/js/common.js'
import { getGoodsInfo, getDetailRemGoods, getHotGoods, checkLogin, weshare, getComment } from '@/api/zh/detail.js'
import { favoriteGoods } from '@/api/zh/mine.js'
import { getBatch } from '@/api/public.js'
// import wx from 'weixin-js-sdk' ;
export default {
  name:'Index',
  components:{ GoodsSwipe, MDot, MSku, GoodsColumns, MDivider, MScrollTop, CommentList, Qlogin },
  data(){
    return {
      tab:0,          // tabs选中的面板
      loading:false,
      num:2,
      list:2,
      skuModal:false,
      goodsId: '',
      off: 0,
      cartAnimate:false,
      dotAnimate:false,
      scrollTop:false,
      goodsInfo: {
        min_price: 0,
        max_price: 0,
        off: 0,
        pre_info: '',
        is_pre: '',
        goods_info: ''
      },
      goodsInfoImg: {},
      goodsList: [[],[]],  // 热门推荐或者热销商品列表
      skuData: [],
      skuBtn : '加入购物车',
      commentList: [],
      isNew: false,
      selectSkuList: [],
      timer: null,
      batch: {},
      isViewLogin: false,
      isQOrder: false,
      sharePrice: 0,
      sharePrePrice: 0,
      costPrice: 0,
      shareBarcode: '',
      isPre: false,
      agent_id: ''
    }
  },
  created() {
    this.goodsId = this.$route.params.id
    
    this.$store.dispatch('getCart')

    this.getBatchHandle()

    if(this.$route.query.qorder && this.$route.query.qorder == 1) {
      this.isQOrder = true
    } else {
      this.isQOrder = false
    }
  },
  mounted(){
    if(this.$route.query.agent_id) {
      this.agent_id = this.$route.query.agent_id
    }
    this.getGoodsInfoHandle();
    this.getHotGoodsHandle()
    this.getDetailRemGoodsHandle()
    this.getCommentHandle()

    if(this.$route.query.pre && this.$route.query.pre == 1) {
      this.isNew = true
    }
  },
  methods:{
    gotoLink() {
      // 链接跳转
      if(this.goodsInfo.merchant_id == 1 && this.goodsInfo.pre_info.is_pre == 1) {
        this.$router.push(`/zh/presale`)
      } else if(this.goodsInfo.merchant_id == 1 && this.goodsInfo.is_pre == 0) {
        this.$router.push(`/zh/mooby`)
      } else {
        this.$router.push(`/zh/merchant/index/${this.goodsInfo.merchant_id}`)
      }
    },
    goodsBack() {
      // 返回
      if (sessionStorage.getItem('preLink')) {
        this.$router.push('/')
      }else if(this.$route.query.back) {
        this.$router.push(this.$route.query.back)
      } else {
        this.$router.go(-1)
      }
    },
    getBatchHandle() {
      // 获取批次时间
      getBatch().then(res => {
        if(res) {
          this.batch = res.data
        }
      })
    },
    // 内容滑动事件
    handleScroll(e){
      let a =  (e.target.scrollTop / 375) > 0.45  ?  0.45 : (e.target.scrollTop / 375)

      this.$refs.bar.style.background = `rgba(0,0,0, ${a})`
      if(e.target.scrollTop >= 500){
        this.scrollTop = true
      }else{
        this.scrollTop = false
      }

      this.$refs.bar.style.opacity = 0

      // let timer = null
         clearTimeout(this.timer)
       this.timer =  setTimeout( () => { 
         this.$refs.bar.style.opacity = 1
       },300) 

    },
    goToStore() {
      // 跳转商家页面
      if(this.goodsInfo.merchant_id == 1 && this.$route.query.pre && this.$route.query.pre == 1) {
        this.$router.push(`/zh/presale`)
      } else {
        this.$router.push(`/zh/merchant/index/${this.goodsInfo.merchant_id}`)
      }
    },
    selectSku(data) {
      // 选择规格
      this.sharePrice = data.price
      this.sharePrePrice = data.price
      this.costPrice = data.costPrice
      this.shareBarcode = data.barcode
      this.selectSkuList = data.sku
    },
    // 点击  请选择
    handleShowSku(){
      this.goodsId = this.$route.params.id
      this.skuModal = true
    },
    // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      this.cartAnimate = true

      let timer = setTimeout( () => {
        this.cartAnimate = false
        this.dotAnimate = true 

        
        let timer1 = setTimeout(()=> {
          this.dotAnimate = false
          clearTimeout(timer1)
        },400)

        clearTimeout(timer)

      },660)
      
    },
    // 切换 tab
    handleTab(tab){
      let left = 19.13333 + tab  * 50
      this.$refs.tabActive.style.left = left + 'vw'
      this.tab = tab
      if(tab == 1){
        this.list = 5
      } else{
        this.list = 2
      }
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
    getGoodsInfoHandle(isPre = false) {
      // 获取商品详细信息
      let params = {
        pre: isPre ? 1 : this.$route.query.pre,
        sign: this.$route.query.sign, 
      }
      if(this.agent_id) {
        params.agent_id = this.agent_id
      }
      getGoodsInfo(this.$route.params.id, params).then(res => {
        if(res) {
          this.off = res.data.off;
          this.goodsInfo = res.data;
          this.goodsInfoImg = res.data.goods_info.goods_details_url
          this.wxShare();
          if((this.goodsInfo.activity_min_price *1).toFixed(0) != 0) {
            this.sharePrice = this.goodsInfo.activity_min_price
          } else {
            this.sharePrice = (this.goodsInfo.min_price* (this.goodsInfo.off == 0 ? 1 : this.goodsInfo.off / 100)).toFixed(2)
          }
          this.costPrice = this.goodsInfo.min_price
          if (this.$route.query.pre == 1) {
            this.sharePrePrice = (this.goodsInfo.pre_info.pre_min_price* (this.goodsInfo.off == 0 ? 1 : this.goodsInfo.off / 100)).toFixed(2)
          }
          if(this.goodsInfo.pre_info.is_pre == 1) {
            if(!this.isPre && this.$route.query.pre == 0) {
              this.getGoodsInfoHandle(true)
            }
            this.isPre = true
            this.isNew = true
          }

          //保存主图以便分享使用
          if(this.$store.state.isMiniProgram && this.goodsInfo.round_pic_url.length > 0) {
            window.wx.miniProgram.postMessage({
              data: {
                path: this.$route.fullPath,
                mate: {title: this.goodsInfo.goods_info.name, content: {description: ''}},
                shareImage: this.goodsInfo.round_pic_url[0]
              }
            })
          }
        } else {
          this.$router.push('/zh/offshelf')
        }
      })
    },
    getDetailRemGoodsHandle() {
      // 获取推荐商品
      getDetailRemGoods({goods_id: this.$route.params.id}).then(res => {
        if(res) {
          if(res.data.data.length > 0) {
            this.goodsList[0].push(res.data.data.slice(0, 6))
          }
          if(res.data.data.length > 6) {
            this.goodsList[0].push(res.data.data.slice(6, 12))
          }
          if(res.data.data.length > 12) {
            this.goodsList[0].push(res.data.data.slice(12, 18))
          }
        }
      })
    },
    getHotGoodsHandle() {
      // 获取热销商品
      getHotGoods(this.$route.params.id).then(res => {
        if(res) {
          if(res.data.data.length > 0) {
            this.goodsList[1].push(res.data.data.slice(0, 6))
          }
          if(res.data.data.length > 6) {
            this.goodsList[1].push(res.data.data.slice(6, 12))
          }
          if(res.data.data.length > 12) {
            this.goodsList[1].push(res.data.data.slice(12, 18))
          }
        }
      })
    },
    favoriteGoodsHandle() {
      // 收藏商品
      let params = {
        status: this.goodsInfo.goods_collect == null ? 1 : 2
      }
      favoriteGoods(this.$route.params.id,params).then(res => {
        if(res) {
          if(this.goodsInfo.goods_collect == null) {
            this.$set(this.goodsInfo, 'goods_collect', 1)
          } else {
            this.$set(this.goodsInfo, 'goods_collect', null)
          }
          console.log(this.goodsInfo.goods_collect)
          this.$notify({ type: 'success', message: res.msg})
        }
      })
    },

    // 显示sku 弹窗
    showSkuModel(title) {
      // 判断是否登录状态
      if(this.isQOrder) {
        checkLogin().then(res => {
          if(res) {
            if(res.status == 0) {
              this.isViewLogin = true
            } else {
              this.skuBtn = title
              this.skuModal = true
            }
          } else {
            this.isViewLogin = true
          }
        })
      } else {
        this.skuBtn = title
        this.skuModal = true
      }
    },
    // 获取折扣
    handleBargain() {
      this.$router.push('/zh/bargain')
    },
    getCommentHandle() {
      // 获取评论
      getComment({page: 1, goods_id: this.$route.params.id}).then(res => {
        if(res) {
          res.data.data.forEach(el => {
            el.created_at = parseTime(el.created_at)
          });
          this.commentList = res.data.data.slice(0, 1)
        }
      })
    },
    shareHandle() {
      // 分享
      let data = {
        id: this.goodsInfo.id,
        img: this.goodsInfo.round_pic_url[0] || '',
        title: this.goodsInfo.goods_info.name,
        price: this.sharePrice,
        prePrice: this.sharePrePrice,
        costPrice: this.costPrice,
        barcode: this.shareBarcode,
        url: 'https://m.moobyyoho.com' + this.$route.fullPath,
        merchant_id: this.goodsInfo.shops_info.merchant_id,
      }
      if(this.goodsInfo.agent) {
        data.agent_id = this.goodsInfo.agent.id
      }
      localStorage.setItem('share', JSON.stringify(data))
      this.$router.push({path: '/zh/share',query:{pre:this.$route.query.pre}})
    },
    // 微信分享
    wxShare(){
      // let jsUrl = '' // 验证签名所需URL
      let shareUrl = window.location.href // 分享链接的URL
      // if (_isWechat() && _isIOS()) {
      //   // 微信浏览器&&苹果设备, 取出记录的第一次访问的URL
      //   jsUrl = this.$store.state.jsUrl
      // } else {
      //   jsUrl = window.location.href; // 当前页面URL
      // }
      let params = {
        url: window.location.href,
      }
      weshare(params).then(res => {
        if(res) {

           window.wx.config({
              debug: false, // 开启调试模式
              appId: res.data.appId, // 必填，公众号的唯一标识
              timestamp:res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
              signature: res.data.signature,// 必填，签名
              jsApiList: [
                // "onMenuShareTimeline",
                // "onMenuShareAppMessage",
                "updateAppMessageShareData",
                "updateTimelineShareData",
              ] 
          });

        
          const that = this;
           window.wx.ready( function() {
             window.wx.checkJsApi({
                jsApiList: ["showMenuItems"],
                success: function () {
                      window.wx.showMenuItems({
                        menuList: [
                            'menuItem:share:appMessage', //发送给朋友

                            'menuItem:share:timeline' //分享到朋友圈
                        ]
                    });
                }
             });

             window.wx.updateTimelineShareData({
              title: "MOOBY YOHO", // 分享标题
              link: shareUrl, // 分享链接
              imgUrl: that.goodsInfo.round_pic_url[0].url || 'https://m.moobyyoho.com/load.png', // 分享图标
              desc: that.goodsInfo.goods_info.name || '好商品有我就够了 MoobyYoho 点击查看详情', // 分享描述
              success () {
                //  that.$notify({ type: 'success', message: '分享成功' });
              },

            });
             window.wx.updateAppMessageShareData({
              title: "MOOBY YOHO", // 分享标题
              link: shareUrl, // 分享链接
              imgUrl: that.goodsInfo.round_pic_url[0].url || 'https://m.moobyyoho.com/load.png', // 分享图标
              desc: that.goodsInfo.goods_info.name || '好商品有我就够了 MoobyYoho 点击查看详情', // 分享描述
              success () {
                //  that.$notify({ type: 'success', message: '分享成功' });
              },
            });
          })
        }
      })
    },
    loginSuccess() {
      // 登录成功
      this.isViewLogin = false
    }
  },
  watch: {
    '$route.params.id'(val) {
      if(val) {
        this.$refs.wrapper.scrollTop = 0
        this.goodsList = [[],[]]
        this.selectSkuList = []
        this.getGoodsInfoHandle()
        this.isNew = false
        this.getHotGoodsHandle()
        this.getDetailRemGoodsHandle()
      }
    }
  },

  beforeRouteEnter: (to, from, next) => {
      /*
      *修复iOS版微信 默认是第一次进入应用的URL,这样就会导致错误
      * to.path  /详情/ID
      * location.hash #/xxxx
      */
     next()
      if (_isIOS() && to.path !== location.pathname) {
        location.assign(`${to.fullPath}`);
      } else {
        next();
      }
    
  }
}
</script>

<style lang="less" scoped>
@import './detail.less';
.sign-up{
  width:100%;background-color: #fff;padding-bottom:15px;
  .sign-up-box{
    width: 115px;
    height: 24px;
    background: linear-gradient(325.99deg, #F03C18 -13.11%, #F06448 110.51%);
    border-radius: 2px;
    font-size: 12px;color:#fff;text-align: center;
    line-height: 24px;
    margin-left:16px;
    margin-top:-10px;
  }
}
</style>